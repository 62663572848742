<template>
  <El-Dialog title="策展人登入" v-model="show" width="360px">
    <El-form
      :ref="'formRef'"
      :label-position="'top'"
      label-width="100px"
      :model="form"
      :rules="rules"
    >
      <El-form-item label="帳號" class="mb-0">
        <El-input v-model="form.username"></El-input>
        <router-link to="/" class="text-gray-500 text-xs link"
          >如何成為策展人?</router-link
        >
      </El-form-item>
      <El-form-item label="密碼">
        <El-input v-model="form.password" type="password"></El-input>
      </El-form-item>
      <El-form-item class="text-center !mt-12" size="large">
        <El-Button
          :loading="loading"
          class="xl"
          type="primary"
          @click="onSubmit('form')"
          >登入</El-Button
        >
      </El-form-item>
    </El-form>
  </El-Dialog>
</template>

<script>
import { ref, reactive, inject, watch } from 'vue';
import { ElDialog, ElForm, ElFormItem, ElInput, ElButton } from 'element-plus';

// import mitt from '@/mitt';
export default {
  components: { ElDialog, ElForm, ElFormItem, ElInput, ElButton },
  setup() {
    const { auth, authenticate } = inject('auth');

    const formRef = ref(null);
    const show = ref(false);

    const form = reactive({
      username: '',
      password: '',
    });
    const rules = {
      username: [
        {
          required: true,
          message: '請輸入',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 5,
          message: 'Length should be 3 to 5',
          trigger: 'blur',
        },
      ],
      password: [
        {
          required: true,
          message: 'Please select Activity zone',
          trigger: 'change',
        },
      ],
    };
    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // loading.value = true;
          authenticate.login({ ...form }).then(() => {
            show.value = false;
            auth.next();
          });
        } else {
          return false;
        }
      });
    };

    watch(auth, (val) => {
      if (val.isLogining) {
        show.value = true;
      }
    });

    return {
      show,
      form,
      onSubmit,
      formRef,
      rules,

      loading: authenticate.loading,
    };
  },
};
</script>
