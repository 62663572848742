<template>
  <div>
    <El-Dialog
      v-bind="modal.state.attrs"
      v-model="modal.state.show"
      :width="$state.isMobile ? '96%' : '670px'"
      @close="modal.toggle()"
    >
      <div class="lg:p-10 lg:pt-4">
        <component :is="modal.state.component"></component>
      </div>
    </El-Dialog>
  </div>
</template>
<script>
import { defineAsyncComponent, getCurrentInstance } from 'vue';
import { ElDialog } from 'element-plus';
import useDialog from '@/components/useDialog';
export default {
  components: {
    ElDialog,
    Editor: defineAsyncComponent(() => import('@/partials/helper/Editor.vue')),
    Solid: defineAsyncComponent(() => import('@/partials/helper/Solid.vue')),
    Visit: defineAsyncComponent(() => import('@/partials/helper/Visit.vue')),
    Entry: defineAsyncComponent(() => import('@/partials/helper/Entry.vue')),
    Depth: defineAsyncComponent(() => import('@/partials/helper/Depth.vue')),
    Floor: defineAsyncComponent(() => import('@/partials/helper/Floor.vue')),
    Selection: defineAsyncComponent(() =>
      import('@/partials/helper/Selection.vue')
    ),
    Size: defineAsyncComponent(() => import('@/partials/helper/Size.vue')),
    Blueprint: defineAsyncComponent(() =>
      import('@/partials/helper/Blueprint.vue')
    ),
  },
  setup() {
    const modal = useDialog();
    const _instance = getCurrentInstance();
    const root = _instance.appContext;
    root.config.globalProperties.$modal = modal;
    return { modal };
  },
};
</script>
