import trackService from '@/services/Track';
export const pass = (opt, stat) => {
  console.log('track:' + JSON.stringify(opt));
  let test = false;
  if (test) return;
  trackService.pass({
    ea: opt.ea || 'open',
    ec: opt.ec || 'home', //name
    el: opt.el, //id
    ...stat,
  });
};

export default function track(opt) {
  pass(opt);

  const checkStay = setInterval(() => {
    pass({ ea: 'stay' });
  }, 30 * 60 * 1000);

  const clear = () => {
    clearTimeout(checkStay);
  };

  return clear;
}
