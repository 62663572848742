<template>
  <div>
    <el-drawer v-model="show_" :size="size" :show-close="false">
      <template v-slot:title>
        <div class="h-[52px] bg-primary flex">
          <el-button
            @click.prevent="show_ = false"
            type="text"
            class="ml-auto mr-4"
          >
            <el-icon color="#fff" :size="30" class="no-inherit">
              <close />
            </el-icon>
          </el-button>
        </div>
      </template>
      <slot></slot>
    </el-drawer>
  </div>
</template>

<script>
import { ElDrawer, ElButton, ElIcon } from 'element-plus';

import { Close } from '@element-plus/icons';
import { computed, toRefs } from 'vue';

export default {
  components: {
    ElIcon,
    Close,
    ElDrawer,
    ElButton,
  },
  props: {
    show: { type: Boolean, default: false },
    size: { type: Number, default: 200 },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const { show } = toRefs(props);
    const show_ = computed({
      get() {
        return show.value;
      },
      set(val) {
        console.log(val);
        emit('update:show', val);
      },
    });

    return {
      show_,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-overlay) .el-drawer__header {
  padding: 0 !important;
}
</style>
