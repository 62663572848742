import _ from 'lodash';
import {
  Mesh,
  BufferGeometry,
  MeshStandardMaterial,
  MeshPhongMaterial,
  MeshBasicMaterial,
  LinearEncoding,
} from 'three';

import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

const mergeMesh = function (insertedMeshes) {
  let mergedMesh;
  let materials = [],
    geometries = [],
    mergedGeometry = new BufferGeometry(),
    meshMaterial;

  insertedMeshes.forEach(function (mesh, index) {
    mesh.updateMatrix();
    geometries.push(mesh.geometry);

    meshMaterial = new MeshPhongMaterial({
      map: mesh.material.map,
    });

    meshMaterial.map.encoding = LinearEncoding;
    // mesh.material.map.encoding = LinearEncoding;
    // meshMaterial = new MeshStandardMaterial(mesh.material);

    materials.push(meshMaterial);
  });

  mergedGeometry = mergeBufferGeometries(geometries, true);
  mergedGeometry.groupsNeedUpdate = true;

  mergedMesh = new Mesh(mergedGeometry, materials);

  return mergedMesh;
};

export { mergeMesh };
