// http.ts
import axios from 'axios';
import qs from 'qs';
// import { ElMessage } from "element-plus"

const showStatus = (status) => {
  let message = '';
  switch (status) {
    case 400:
      message = '請求錯誤(400)';
      break;
    case 401:
      message = '未授權，請重新登錄(401)';
      break;
    case 403:
      message = '拒絕訪問(403)';
      break;
    case 404:
      message = '請求出錯(404)';
      break;
    case 408:
      message = '請求超時(408)';
      break;
    case 500:
      message = '服務器錯誤(500)';
      break;
    case 501:
      message = '服務未實現(501)';
      break;
    case 502:
      message = '網絡錯誤(502)';
      break;
    case 503:
      message = '服務不可用(503)';
      break;
    case 504:
      message = '網絡超時(504)';
      break;
    case 505:
      message = 'HTTP版本不受支持(505)';
      break;
    default:
      message = `連接出錯(${status})!`;
  }
  return `${message}，請檢查網絡或聯繫管理員！`;
};
// 聲明一個 Map 用於存儲每個請求的標識 和 取消函數
const pending = new Map();
/**
 * 添加请求
 * @param {Object} config
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        // 如果 pending 中不存在当前请求，则添加进去
        pending.set(url, cancel);
      }
    });
};
/**
 * 移除请求
 * @param {Object} config
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');
  if (pending.has(url)) {
    // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

/**
 * 清空 pending 中的請求（在路由跳轉時調用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

const service = axios.create({
  // 聯調

  // baseURL:
  // process.env.NODE_ENV === 'production'
  //   ? `${process.env.VUE_APP_API_URL}/api`
  //   : `/api`,
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8',
      // Authorization: 'Bearer ' + 'KNN5364HAGX8GHGD6YNTH4MKD7J9G5AA',
    },
  },
  // 是否跨站點訪問控制請求
  withCredentials: true,
  timeout: 30000,
  // transformRequest: [
  //   (data) => {
  //     data = JSON.stringify(data);
  //     return data;
  //   },
  // ],
  validateStatus() {
    // 使用async-await，處理reject情況較為繁瑣，所以全部返回resolve，在業務代碼中處理異常
    return true;
  },
  transformResponse: [
    (data) => {
      // console.log(data.startsWith('{'));
      // console.log(typeof data);
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      return data;
    },
  ],
});

// 請求攔截器
service.interceptors.request.use(
  (config) => {
    removePending(config); // 在請求開始前，對之前的請求做檢查取消操作
    addPending(config); // 將當前請求添加到 pending 中
    // if (config.url === '/member/status') {

    if (process.env.NODE_ENV !== 'production') {
      config.headers['Mobile-Token'] = process.env.VUE_APP_TOKEN;
    }

    // }
    // let token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `${token}`;
    // }
    return config;
  },
  (error) => {
    // 錯誤拋到業務代碼
    error.data = {};
    error.data.msg = '服務器異常，請聯繫管理員！';
    return Promise.resolve(error);
  }
);

// 響應攔截器
service.interceptors.response.use(
  (response) => {
    removePending(response); // 在請求結束後，移除本次請求
    const status = response.status;

    let msg = '';
    if (status < 200 || status >= 300) {
      // 處理http錯誤，拋到業務代碼
      msg = showStatus(status);
      if (typeof response.data === 'string') {
        response.data = { msg };
      } else {
        response.data.msg = msg;
      }
    }

    return response.data;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error.message);
    } else {
      // handle error code
      // 錯誤拋到業務代碼
      error.data = {};
      error.data.msg = '請求超時或服務器異常，請檢查網絡或聯繫管理員！';
    }
    return Promise.reject(error);
  }
);

export default service;
